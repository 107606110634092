import Service from "../Service";
import Vue from "vue";
const resource = "listsupplies/";

export default {
  save(prd, requestID) {
    return Service.post(resource + "savedetail", prd, { params: { requestID: requestID }, });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "paginationdetail", parameters, {
      params: { requestID: requestID },
    });
  },
};