<template>
  <div>
    <v-row>
    
      <v-col lg="12">
       <supplies :isAdmin="true"  :DedID="1" :OriginID="originID" :height="200"> >
        </supplies> 
      </v-col>    
    </v-row>
  </div>
</template>

<script>
import Supplies from "@/views/TechnicalAssistance/ListSupplies/Supplies";
import Definicion from "@/views/TechnicalAssistance/ListSupplies/Definition";
export default {
  components: {
        Definicion
        , Supplies
 },

   data() {
    return {
      parentID: 0,
      dedID:0,
      originID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].DedValue;
     this.originID=rows[0].DedID;
    },  
  },
};
</script>
